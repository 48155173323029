@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.rc-table {
  border-left: 1px solid rgb(209 213 219);
  border-right: 1px solid rgb(209 213 219);
}

.rc-table-thead {
  border-bottom: 1px solid rgb(209 213 219);
}


.rc-table-thead .rc-table-cell {
  padding-bottom: 10px;
}


.rc-table-row {
  cursor: pointer;
  height: 60px;
  border-top: 1px solid rgb(209 213 219);
}

.rc-table-row:hover {
  background-color: rgba(100, 181, 145, 0.2);
}

.rc-table-expanded-row {
  /* border-bottom: 1px solid rgb(209 213 219); */
}

.rc-table-expanded-row h5 {
  font-size: 16px;
  font-weight: bold;
}

.rc-table-expanded-row p {
  font-size: 16px;
}

.rc-table-expanded-row button {
  color:#64b591 !important;
}


.rc-table-expanded-row a {
  margin-top: 30px !important;
  color:#64b591 !important;
  text-decoration: none;
  
}




/* LOGIN */
.Login {
  background-color: #f6f6f9;
  height: 100vh;
  padding-top: 120px;
}

.Login div {
  padding:60px;
  padding-left: 90px;
  padding-right: 90px;
  display: flex;
  flex-direction:column;
  align-items:center;
  max-width: 550px;
  margin: auto;
  background-color: #fff;
  border-radius: 3px;
  max-height: 500px;
}

/* .Login input {
  margin: 5px;
  width: 100%;
  padding-left: 15px;
  border-radius: 8px;
} */


/* .Login select {
  height: 40px;
  margin: 5px;
  width: 100%;
  padding-left: 15px;
  border-radius: 8px;
  padding:8px;
  border: 1px solid #D8D8D8;
} */

/* .Login button {
  margin-top: 5px;
  width: 100%;
  border-radius: 100px;
  border: none;
  height: 35px;
  background-color: #5096FF;
  color: #fff;
} */

.Login button:hover {
  opacity: 0.7;
}

/* .Login h1 {
  font-size: 24px;
} */


.helper {
  border: 1px solid rgba(235, 232, 213, 1);
  padding:12px;
  border-radius: 3px;
  margin: auto;
  margin-top: 30px !important;
  /* margin-bottom: -30px; */
  width: 100% !important;
  font-size: 14px;
  background-color: rgba(255, 253, 237, 1);
}

.small-helper {
  font-size: 12px;
  color: rgba(82, 93, 103, 1);
}
